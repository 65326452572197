.thumbnail-container {
    display: inline-block;
    width: calc(33.333% - 10px);
    margin: 5px;
    position: relative;
}

.thumbnail {
    width: 100%;
    height: auto;
    cursor: pointer;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    padding: 20px;
    border-radius: 5px;
    margin: 10% auto;
    border: 1px solid #888;
    width: 80%;
    max-width: 700px;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.modal-image-container {
    text-align: center;
}

.modal-image {
    max-width: 80%;
    max-height: 40%;
    width: 600px;
    height: auto;
}

